import $ from '@rover/globals/jquery';

type JQueryForm = JQuery<HTMLElement> & { ajaxForm: (arg0: any) => void };

const showFailure = (): void => {
  $('.js-email-capture-footer-form-failure').fadeIn('fast');
};

const showThanks = (response): void => {
  if (response.status !== 'success') {
    showFailure();
    return;
  }

  $('.js-email-capture-footer-form-failure').fadeOut('fast');
  $('.js-email-capture-footer-form-initial').fadeOut('fast', () => {
    $('.js-email-capture-footer-form-thanks').fadeIn('fast');
  });
};

const initializeEmailCaptureForm = (): void => {
  ($('.js-email-capture-form') as JQueryForm).ajaxForm({
    success: showThanks,
  });
};

const init = (): void => {
  initializeEmailCaptureForm();
};

export default {
  init,
};
