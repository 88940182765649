import $ from '@rover/globals/jquery';
import Ajax from '@rover/utilities/ajax';
import Auth from '@rover/utilities/auth';
import Carousels from '@rover/utilities/carousels';
import Events from '@rover/utilities/events';
import FlagListing from '@rover/utilities/flagListing';
import GusTips from '@rover/utilities/gus-tips';
import TextUtils from '@rover/utilities/text-utilities';
import Validations from '@rover/utilities/validation';
import ConsoleFixes from '@rover/utilities/console';
import jQueryFixes from '@rover/utilities/jquery-fixes';
import loadNavigationEventsIntoDataLayer from '@rover/utilities/loadNavigationEventsIntoDataLayer';
import applyNavigationEventClickHandlers from '@rover/utilities/applyNavigationEventClickHandlers';

import Buttons from './utilities/buttons';
import Galleries from './utilities/galleries';
import Modals from './utilities/modals';
import Authenticated from './Authenticated';
import EmailCaptureFooterForm from './components/email_capture_footer_form';
import InternalReferrer from './InternalReferrer';
import AppInstallBanner from './AppInstallBanner';
import Footer from './components/footer';

// This stays outside the IIFE since it needs to
// fire events before Vue components are loaded

$(() => {
  InternalReferrer.init();
  Ajax.init();
  EmailCaptureFooterForm.init();
  AppInstallBanner.init();
  Footer.init();

  Auth.init();
  Buttons.init();
  Carousels.init();
  Events.init();
  FlagListing.init();
  GusTips.init();
  Modals.init();
  TextUtils.init();
  Validations.init();
  ConsoleFixes.init();
  Galleries.init();
  jQueryFixes.init();
  Authenticated.init();

  loadNavigationEventsIntoDataLayer();
  applyNavigationEventClickHandlers();
});
