import $ from '@rover/globals/jquery';

function init() {
  /**
   * Fix jQuery show to work better with Bootstrap hidden/hide classes
   * https://github.com/twbs/bootstrap/issues/9237
   */
  // eslint-disable-next-line prefer-destructuring
  const show = $.fn.show;

  $.fn.show = function () {
    this.removeClass('hidden hide');
    return show.call(this);
  };

  /**
   * Recursively strip whitespace between child elements.
   */
  ($.fn as any).removeWhitespace = function () {
    this.contents()
      .filter(function (this: any) {
        if (this.nodeType === 3) {
          return !/\S/.test(this.nodeValue || '');
        }

        ($(this) as any).removeWhitespace();
        return false;
      })
      .remove();
    return this;
  };
}

const exportJqueryFixes = {
  init,
};

export default exportJqueryFixes;
