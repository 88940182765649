import $ from '@rover/globals/jquery';

import setLeverCookies from '@rover/utilities/lever';

const init = () => {
  $(document).on('click', '.js-careers-link-click-listener', setLeverCookies);
};

export default {
  init,
};
