import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';
import { localStorage } from '@rover/utilities/storage';

import { EVENTS_KEY } from './saveNavigationEvent';

const loadNavigationEventsIntoDataLayer = () => {
  const data = localStorage.getItem(EVENTS_KEY);
  const navigationEvents = (data && JSON.parse(data)) || [];
  navigationEvents.forEach((event) => fireDataLayerEvent(event));
  localStorage.setItem(EVENTS_KEY, JSON.stringify([]));
};

export default loadNavigationEventsIntoDataLayer;
