import type { UtmParams } from '@rover/types/src/ImagePicker';
import appendUrlParams from '../appendUrlParams';

const encodeUtmParams = (rawUtmParams: UtmParams): UtmParams => {
  const res = rawUtmParams;

  Object.keys(res).forEach((key) => {
    res[key] = encodeURIComponent(rawUtmParams[key]);
  });

  return res;
};

export const addUtmParamsToUrl = (url: string, utmParams: UtmParams): string => {
  // utm_source is required: https://ga-dev-tools.appspot.com/campaign-url-builder/
  if (!utmParams || !utmParams.utm_source || !utmParams.utm_source.length) return url;
  return appendUrlParams(url, encodeUtmParams(utmParams));
};

export { UTM_PARAMS } from './utm.constants';
