// N.B. IntersectionObserver is in a function call so that on browsers that need a polyfill
// for IntersectionObserver, we don't get a compile time error if the bundle with this file is
// loaded before the polyfill
function getObserver(elements, callback) {
  const observer = new IntersectionObserver((entries) => {
    entries.filter((e) => e.isIntersecting).forEach((e) => callback(e, observer));
  });
  return observer;
}

function loadImageSrc(entry, observer) {
  const lazyImage = entry.target;
  const source = lazyImage.dataset.src;
  const sourceSet = lazyImage.dataset.srcset;

  // N.B. it's possible for <img> tags to not have data-src or data-src-set because
  // there are conditions where the data from a backend source (e.g. a search result object)
  // doesn't have them. In those cases, we should not change the `src` attribute because
  // that results in the "broken image" browser pic instead of the nicer-looking placeholder.
  if (source) {
    lazyImage.src = source;
  }

  if (sourceSet) {
    lazyImage.srcset = sourceSet;
  }

  lazyImage.classList.remove('js-lazy');
  observer.unobserve(lazyImage);
}

function loadBackgroundImage(entry, observer) {
  const div = entry.target;
  const customSrc = div.dataset.backgroundSrc;

  // Since some background images are in CSS, and some in HTML (e.g. when it's dynamic),
  // there are two ways a div can have a lazy-loading background:
  // 1) .lazy-background class that has a background-image: *** !important with the placeholder.
  //     When we remove that class, the browser recomputes the CSS for that element and the other
  //     classes' background is loaded
  // 2) a `data-background-src` attribute, which is then set to the div's new background using an
  //    inline style.
  if (customSrc) {
    div.style.backgroundImage = `url("${customSrc}")`;
  }

  div.classList.remove('lazy-background');
  div.classList.remove('js-lazy-background');
  observer.unobserve(div);
}

function startObserving(elements, callback) {
  // IE11 doesn't support .forEach if `elements` is of type `modeList`
  const elementArray = Array.from(elements);

  const observe = () => {
    const observer = getObserver(elements, callback);
    elementArray.forEach((el: any) => observer.observe(el));
  };

  if (typeof IntersectionObserver === 'function') {
    observe();
  } else {
    document.addEventListener('IntersectionObserverLoaded', observe);
  }
}

export const observeLazyImages = (elements) => {
  startObserving(elements, loadImageSrc);
};
export const observeLazyBackgrounds = (elements) => {
  startObserving(elements, loadBackgroundImage);
};
