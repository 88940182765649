import {
  CookiePurpose,
  createCookie,
  getCookie,
  hasCookie,
} from '@rover/rsdk/src/modules/Network/cookies';

export default class {
  static init() {
    this.setEntryPageCookie();
    this.setUtmCookies();
  }

  static getUrlParam(name) {
    return (
      decodeURIComponent(
        (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.search) || [
          null,
          '',
        ])[1].replace(/\+/g, '%20')
      ) || null
    );
  }

  static getUrlLocation() {
    return escape(window.location.pathname);
  }

  static setEntryPageCookie() {
    if (!hasCookie('entry_page')) {
      createCookie(CookiePurpose.Performance, 'entry_page', this.getUrlLocation(), 999999);
    }
  }

  static setUtmCookies() {
    const utmParams = ['campaign', 'source', 'medium', 'content', 'term'];
    const override = this.getUrlParam('utm_nooverride') !== '1';
    const hasFirstTimestamp = hasCookie('f_iso');
    const timestamp = new Date().toISOString();

    utmParams.forEach((param) => {
      const utmParam = `utm_${param}`;
      const utmValue = this.getUrlParam(utmParam);
      if (!utmValue) return;

      if (!hasFirstTimestamp) {
        createCookie(CookiePurpose.Marketing, `f_${utmParam}`, utmValue, 365);
        createCookie(CookiePurpose.Marketing, 'f_iso', timestamp, 365);
      }

      if (!hasFirstTimestamp || override) {
        createCookie(CookiePurpose.Marketing, `l_${utmParam}`, utmValue, 365);
        createCookie(CookiePurpose.Marketing, 'l_iso', timestamp, 365);
      }
    });
  }

  static getCookie(name) {
    return getCookie(name);
  }

  static createCookie(purpose, name, value, days) {
    createCookie(purpose, name, value, days);
  }

  static hasCookie(name) {
    return hasCookie(name);
  }
}
