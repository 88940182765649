import Rover from '@rover/globals/Rover';
import $ from '@rover/globals/jquery';
import ModalView from '../components/rover_modal';

function showModal() {
  const options = {
    content: $($(this).attr('data-target')).html(),
    title: $(this).attr('data-title'),
    disableCancel: $(this).attr('data-modal-blocking'),
    padding: !($(this).attr('data-modal-padding') === 'false'),
  };

  const modal = new ModalView(options);

  modal.open();
}

function initializeBlockingModal() {
  if (Rover.disable_blocking_modal) return;
  if ($(document.body).hasClass('js-disable-blocking-modal')) return;

  Rover.blocking_modals.handleBlockingModal();
}

function init() {
  initializeBlockingModal();
}

export default {
  init,
  showModal,
  initializeBlockingModal,
};
