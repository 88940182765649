import $ from '@rover/globals/jquery';
import i18n from '@rover/utilities/translation';

function getWords(s) {
  const trimmedString = s.replace(/(^\s*)|(\s*$)/gi, '');
  if (trimmedString === '') return [];
  return trimmedString.split(/\s+/);
}

function getWordCount(s) {
  return getWords(s).length;
}

function getCharacterCount(s) {
  return s.replace(/(^\s*)|(\s*$)/gi, '').length;
}

($.fn as any).wordCount = function wordCount() {
  return getWordCount($(this).val());
};

function updateWordCount(e) {
  const $el = $(e.currentTarget);
  const $counter = $el.siblings().find('.js-word-count-input-counter');
  const $text = $el.siblings().find('.js-word-count-input-text');
  const minCharacters = $el.attr('data-min-characters');

  if (minCharacters != null) {
    const characterCount = getCharacterCount($el.val());
    let charactersNeeded = 0;
    if (minCharacters) charactersNeeded = parseInt(minCharacters, 10) - characterCount;
    $counter.html(Math.max(0, charactersNeeded).toString());

    if (!minCharacters) {
      return;
    }

    if (characterCount === 0) {
      $text.html(i18n._('characters minimum required'));
    } else if (charactersNeeded > 0) {
      $counter.addClass('text-danger');
      $text.addClass('text-danger');
      $text.html(i18n._('more characters needed'));
    } else {
      $counter.removeClass('text-danger');
      $text.removeClass('text-danger');
      $text.html('<i class="text-primary rover-icon rover-icon-in-check-mark">');
      $counter.html('');
    }
  } else {
    const wordCount = getWordCount($el.val());
    let wordsNeeded = 0;
    const minWords = $el.attr('data-min-words');
    if (minWords) wordsNeeded = parseInt(minWords, 10) - wordCount;
    $counter.html(Math.max(0, wordsNeeded).toString());

    if (!minWords) {
      return;
    }

    if (wordCount === 0) {
      $text.html(i18n._('word minimum'));
    } else if (wordsNeeded > 0) {
      $counter.addClass('text-danger');
      $text.addClass('text-danger');
      $text.html(i18n._('more words needed'));
    } else {
      $counter.removeClass('text-danger');
      $text.removeClass('text-danger');
      $text.html('<i class="text-primary rover-icon rover-icon-in-check-mark">');
      $counter.html('');
    }
  }
}

function updateCharCount(evt) {
  const $target: any = $(evt.target);
  $target.siblings().find('.js-char-count-input-counter').html($target.val().length);
}

function initWordCount() {
  $('.js-word-count-input').trigger('input');
}

function initCharCount() {
  $('.js-char-count-input').trigger('input');
}

function init() {
  $('.js-word-count-input').on('input', updateWordCount);
  $('.js-char-count-input').on('input', updateCharCount);
  initWordCount();
  initCharCount();
}

const exportedTextUtilities = {
  init,
  getWords,
  getWordCount,
  getCharacterCount,
  updateWordCount,
  updateCharCount,
  initWordCount,
  initCharCount,
};

export default exportedTextUtilities;
