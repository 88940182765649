import { observeLazyBackgrounds, observeLazyImages } from '@rover/utilities/lazyImageObserver';

declare global {
  interface Window {
    util: any;
  }
}

window.util = window.util || {};
window.util.lazyImageLoader = {
  observeLazy() {
    observeLazyImages(document.querySelectorAll('img.js-lazy'));
    observeLazyBackgrounds(document.querySelectorAll('div.js-lazy-background'));
  },
};
