import $ from '@rover/globals/jquery';
import Events from '@rover/utilities/events';
import GusTips from '@rover/utilities/gus-tips';

import Modals from './modals';

function disableClick() {
  return false;
}

function selectRadio() {
  $(this).find('input[type="radio"]:not(:disabled)').prop('checked', true);
}

function handleClockButtonClick(e) {
  e.preventDefault();
  const $this = $(this);
  let $triggerTarget = $($this.attr('data-trigger-target'));
  if (!$triggerTarget.length) {
    $triggerTarget = $this.find('.js-timepicker');
  }
  $triggerTarget.focus();
  $triggerTarget.timepicker('show');
}

function handleCalendarButtonClick(e) {
  e.preventDefault();
  const $this = $(this);
  let $triggerTarget = $($this.attr('data-trigger-target'));
  if (!$triggerTarget.length) {
    $triggerTarget = $this.find('.js-date-picker');
  }
  $triggerTarget.datepicker('show');
}

function init() {
  if ($.fn.button && $.fn.button.noConflict !== undefined) {
    const btn = $.fn.button.noConflict(); // reverts $.fn.button to jqueryui btn
    $.fn.btn = btn; // assigns bootstrap button functionality to $.fn.btn
  }
  $('.rover-btn').btn();

  // Enable button toggling functionality for rover buttons. Bootstrap hardcodes
  // their listener on .btn, so we have to do the same for .rover-btn
  $(document).on('click.bs.button.data-api', '[data-toggle^="button"]', (e) => {
    let $roverBtn = $(e.target);

    // Account for the case when the click event occurred on something
    // inside the rover button
    if (!$roverBtn.hasClass('rover-btn')) {
      $roverBtn = $roverBtn.closest('.rover-btn');
    }

    $roverBtn.btn('toggle');
    if (e.target.tagName.toLowerCase() !== 'input') e.preventDefault();
  });

  $('.js-gus-tip-dismiss').on('click', GusTips.handleGusTipDismiss);
  $('.js-show-modal').on('click', Modals.showModal);
  $('.js-disable-click').on('click', disableClick);

  $(document).on('click', '.js-datepicker-calendar-trigger', handleCalendarButtonClick);
  $(document).on('click', '.js-timepicker-clock-trigger', handleClockButtonClick);
  $(document).on('click', '.js-clickable-for-radio', selectRadio);
  $(document).on('click', '[data-record-click-event]', Events.recordClickEventHandler);
}

export default {
  init,
  disableClick,
  handleClockButtonClick,
  handleCalendarButtonClick,
};
