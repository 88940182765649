/* eslint-disable rover/no-platform-specific-globals-or-imports */
import $ from '@rover/globals/jquery';
import { removeFocusTrap, trapFocusWithinElement } from '@rover/utilities/modals';

import Alerts from './Alerts';

const flagFormSubmitSuccess = (response, status, xhr, $form) => {
  $form.closest('.js-feedback-modal').modal('hide');
  Alerts.showToast({
    severity: 'success',
    message: "Thank you for flagging this. We'll look at it as soon as possible",
  });
  $(window).scrollTop(0);
};

const initializeFeedbackModal = (): any => {
  const $feedbackModal = $('.js-feedback-modal');
  $('.js-flag-button').on('click', () => {
    if ($feedbackModal.length) {
      const previouslySelectedElement: any = document.activeElement;
      $feedbackModal.modal();
      const tabListener = trapFocusWithinElement($feedbackModal);
      $feedbackModal.on('hide.bs.modal', function (this: any) {
        $(this).off('hide.bs.modal');
        removeFocusTrap(tabListener);
        setTimeout(() => previouslySelectedElement.focus(), 500);
      });
    }
  });
};

function init(this: any) {
  initializeFeedbackModal();
  this.$flagForm = $('.js-flag-form');

  if (!this.$flagForm.find('#reason').length) {
    // the comment is optional when the reason dropdown is present
    this.$flagForm.validate({
      rules: {
        comment: {
          required: true,
          minWords: 4,
        },
      },
    });
  }

  this.$flagForm.autoDisableAndValidateAjaxForm({
    success: flagFormSubmitSuccess,
    resetForm: true,
  });

  if (window.location.hash === '#flag_dialog') {
    $('.js-flag-button').trigger('click');
  }
}

const exportedFlagListing = {
  init,
};

export default exportedFlagListing;
