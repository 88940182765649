import axios from 'axios';

import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';

const getCsrfToken = (): string => {
  let csrfCookieName;
  const $meta = $('meta[name=csrf-cookie-name]');

  if ($meta.length > 0) {
    csrfCookieName = $meta.attr('content');
  } else {
    csrfCookieName = 'csrftoken';
  }

  return $.cookie(csrfCookieName);
};

const initJQueryHeaders = () => {
  $.ajaxSetup({
    headers: Rover.api.getSourceHeader(),
  });
  $(document).ajaxSend((e, jqxhr, settings) => {
    const token = getCsrfToken();
    if (!token) return;

    if (window.isRoverURL(settings.url as string)) {
      jqxhr.setRequestHeader('X-CSRFToken', token);
    }
  });
};

const initAxiosHeaders = () => {
  axios.interceptors.request.use(
    (config) => {
      // if we're requesting a rover url
      if (
        config.url &&
        (config.url.includes(window.location.host) ||
          config.url.startsWith('/') ||
          config.url.startsWith('./') ||
          config.url.startsWith('../'))
      ) {
        const clone = { ...config };
        // clone the config and add our custom headers
        if (clone.headers) {
          clone.headers = { ...config.headers, ...Rover.api.getSourceHeader() };
          const token = getCsrfToken();

          if (token) {
            clone.headers['X-CSRFToken'] = token;
          }
        }

        return clone;
      }

      // non-rover urls get NOTHING, to avoid CORS issues
      return config;
    },
    (error) => Promise.reject(error)
  );
};

function init() {
  initJQueryHeaders();
  initAxiosHeaders();
}

const exportedInit = {
  init,
};

export default exportedInit;
