import uuid from 'uuid';
import {
  CLSAttribution,
  CLSMetricWithAttribution as CLSMetric,
  FCPAttribution,
  FCPMetricWithAttribution as FCPMetric,
  INPAttribution,
  INPMetricWithAttribution as INPMetric,
  LCPAttribution,
  LCPMetricWithAttribution as LCPMetric,
  Metric as MetricWithoutAttribution,
  MetricType,
  onCLS,
  onFCP,
  onINP,
  onLCP,
  onTTFB,
  ReportOpts,
  TTFBAttribution,
  TTFBMetricWithAttribution as TTFBMetric,
} from 'web-vitals/attribution';

import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';
import { getSourceDescription } from '@rover/rsdk/src/modules/Network/userAgent';
import emitMetric from '@rover/utilities/emitMetric';

import fireDataLayerEvent from './fireDataLayerEvent';

const checkIfAuthedUserExperienceCached = (
  cacheStatus: string,
  userIsAuthenticated: boolean,
  pageCategory: string
): void => {
  if (cacheStatus === 'miss-cached' && userIsAuthenticated) {
    captureError(new Error(`Cache status is miss-cached for an authenticated user`), {
      errorInfo: { pageCategory },
    });
  }
};

// doing this instead of importing MetricWithAttribution from web-vitals/attribution because attribution type is too generic and TS fails.
// https://github.com/GoogleChrome/web-vitals/issues/408
interface Metric extends MetricWithoutAttribution {
  attribution: CLSAttribution | FCPAttribution | INPAttribution | LCPAttribution | TTFBAttribution;
}
declare global {
  interface Window {
    edgeCache?: any; // used for setting edgeCacheStatus
  }
}

// the common interface of all `on*` functions from `web-vitals`
interface OnCoreWebVitalFn {
  (onReport: { (metric: MetricType): void }, opts?: ReportOpts): void;
}

function wrapAsError(ambiguousError: unknown): Error {
  if (ambiguousError instanceof Error) return ambiguousError;
  return new Error(
    `Caught a non-Error (${typeof ambiguousError} that was thrown: ${JSON.stringify(
      ambiguousError
    )})`
  );
}

// Wrap any `on*` function from `web-vitals`, returning a promise
// that resolve with the first callback value
function getCWV<MType extends CLSMetric | LCPMetric | TTFBMetric | INPMetric | FCPMetric>(
  onCWV: OnCoreWebVitalFn
) {
  return (): Promise<MType> =>
    new Promise<MType>((resolve) => {
      try {
        onCWV((metric) => resolve(metric as MType));
      } catch (error) {
        captureError(wrapAsError(error));
      }
    });
}

function tokenizeCacheStatus(cacheStatus: string): string {
  return cacheStatus.split(', ').join('-').toLowerCase();
}

export const getCLS = getCWV<CLSMetric>(onCLS);
export const getLCP = getCWV<LCPMetric>(onLCP);
export const getTTFB = getCWV<TTFBMetric>(onTTFB);
export const getINP = getCWV<INPMetric>(onINP);
export const getFCP = getCWV<FCPMetric>(onFCP);

export enum CoreWebVitalMetrics {
  LCP = 'seo.largest_contentful_paint',
  INP = 'seo.interaction_to_next_paint',
  CLS = 'seo.cumulative_layout_shift',
}

export enum OtherWebVitalMetrics {
  TTFB = 'seo.time_to_first_byte',
  FCP = 'seo.first_contentful_paint',
}

export type AllWebVitalMetrics = CoreWebVitalMetrics | OtherWebVitalMetrics;

type WebVitalEvent = Pick<Metric, 'name' | 'value' | 'rating' | 'attribution'> & {
  event: 'core-web-vitals';
  pageview_id: string;
};

const shouldIncludeAttribution = (metric: Metric) => {
  if (!['LCP', 'INP', 'CLS'].includes(metric.name)) {
    return false;
  }
  if (!['poor', 'needs-improvement'].includes(metric.rating)) {
    return false;
  }
  return true;
};

const fireCWVMetricEvent = (
  metric: Metric,
  pageviewId: string,
  cacheStatus: string
): Promise<void> =>
  fireDataLayerEvent({
    event: 'core-web-vitals',
    name: metric.name,
    value: metric.value,
    rating: metric.rating,
    pageview_id: pageviewId,
    cache_status: cacheStatus,
    ...(shouldIncludeAttribution(metric) ? { attribution: metric.attribution } : {}),
  } as WebVitalEvent);

const getCacheStatus = (): string => {
  return typeof window !== 'undefined' && typeof window.edgeCache?.edgeCacheStatus === 'string'
    ? tokenizeCacheStatus(window.edgeCache.edgeCacheStatus)
    : 'na';
};

const emitCWVMetricForPage =
  (pageCategory: string, pageviewId: string, cacheStatus: string) =>
  (metricName: AllWebVitalMetrics) =>
  (metric: Metric): void => {
    fireCWVMetricEvent(metric, pageviewId, cacheStatus);
    emitMetric(
      metricName,
      {
        page_identifier: pageCategory,
        source: getSourceDescription(),
        navigationType: metric.navigationType,
        cacheStatus,
      },
      {
        sampleRate: 0.1,
        metricType: 'distribution',
        metricValue: metric.value,
      }
    );
  };

/**
 * @param pageCategory string   The internal identifier for the set of URLs to which the current page belongs.
 * @param userIsAuthenticated boolean  Whether the current user is authenticated.
 * @returns A promise resolving to a 2-tuple of Largest Contentful Paint and Cumulative Layout Shift.
 * Because each of these may or may not resolve at the same time,
 * it's not recommended to await this function to only get one of these values. If possible,
 * await each `on*` function independently and fail graciously if one or more values never resolves.
 */
export function emitCoreWebVitals(
  pageCategory: string,
  userIsAuthenticated: boolean
): Promise<[Metric, Metric]> {
  // Each of these promises needs to be awaited separately,
  // because each resolves with very different timing.
  // INP, for example, only gets measured if the user interacts
  // with the page at all - we can have CLS and LCP and never get INP.
  const LCP = getLCP();
  const CLS = getCLS();
  const TTFB = getTTFB();
  const INP = getINP();
  const FCP = getFCP();

  const pageviewId = uuid.v4();

  const cacheStatus = getCacheStatus();
  // An logged-in user should never cache a page, throw an error if so
  checkIfAuthedUserExperienceCached(cacheStatus, userIsAuthenticated, pageCategory);

  const emitCWVMetric = emitCWVMetricForPage(pageCategory, pageviewId, cacheStatus);

  INP.then(emitCWVMetric(CoreWebVitalMetrics.INP)).catch((e) => {
    captureError(e);
  });
  LCP.then(emitCWVMetric(CoreWebVitalMetrics.LCP)).catch((e) => {
    captureError(e);
  });
  CLS.then(emitCWVMetric(CoreWebVitalMetrics.CLS)).catch((e) => {
    captureError(e);
  });
  TTFB.then(emitCWVMetric(OtherWebVitalMetrics.TTFB)).catch((e) => {
    captureError(e);
  });
  FCP.then(emitCWVMetric(OtherWebVitalMetrics.FCP)).catch((e) => {
    captureError(e);
  });

  return Promise.all([LCP, CLS]);
}
