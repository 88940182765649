import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import Mustache from '@rover/globals/Mustache';
import GalleryModalView from '../core/views/gallery_modal';

function initializeGalleries() {
  const slides = [];
  const images = $('.js-shareable-image');
  const template = Mustache.template('new_design/social/modal_sharing_buttons');

  images.each((i, image) => {
    const $image = $(image);

    const shareButtons = template.render({
      imageToShare: $image.attr('data-image-to-share'),
      imageThumbnail: $image.attr('data-image-thumbnail'),
      urlToShare: $image.attr('data-url-to-share'),
      contentType: $image.attr('data-content-type'),
      objectId: $image.attr('data-object-id'),
      campaign: $image.attr('data-campaign'),
      trackingContent: $image.attr('data-tracking-content'),
      caption: $image.attr('data-caption'),
      shareLocation: $image.attr('data-share-location'),
      hideable: $image.attr('data-hideable') === 'true',
      hidden: $image.attr('data-hidden') === 'true',
    });

    const slide = {
      type: 'image',
      src: $image.attr('href'),
      unsafeHtmlCaption: shareButtons,
    };

    slides.push(slide);
  });

  const staysGalleryModal = new GalleryModalView({
    classes: 'stays-gallery-modal',
    slides,
  });

  images.each((i, image) => {
    const $image = $(image);

    (val => {
      $image.off('click').on('click', e => {
        e.preventDefault();
        if (staysGalleryModal) staysGalleryModal.openTo(val);
      });
    })(i);
  });
}

function init() {
  initializeGalleries();
}

export default {
  init,
  initializeGalleries,
};
