import saveNavigationEvent from './saveNavigationEvent';

const applyNavigationEventClickHandlers = () => {
  const links = Array.prototype.slice.call(document.querySelectorAll('[data-nav-event]'));
  links.forEach((link) => {
    const navEvent = link.getAttribute('data-nav-event');

    if (navEvent) {
      link.addEventListener('click', () => {
        saveNavigationEvent({
          event: navEvent,
        });
      });
    }
  });
};

export default applyNavigationEventClickHandlers;
