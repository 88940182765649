/**
 * A photo gallery modal built using a royal slider(http://dimsemenov.com/plugins/royal-slider/documentation/)
 * inside of a chromeless Bootstrap modal (see .rover-media-modal)
 *
 * Example usage:
 *
 * import GalleryModalView from 'gallery_modal';
 *
 * const gallery = new GalleryModalView({
 *     slides: [
 *         { type: "image", src: "something.jpg", caption: "A photo of something" },
 *         { type: "image", src: "something2.jpg", caption: "Another photo of something" }
 *     ]
 * });
 *
 * // Open the gallery
 * gallery.open();
 *
 * // Open the gallery to 5th slide
 * gallery.openTo(4)
 *
 */
import $ from '@rover/globals/jquery';
import _ from '@rover/globals/underscore';
import Mustache from '@rover/globals/Mustache';
import dataLayer from '@rover/globals/dataLayer';
import Rover from '@rover/globals/Rover';
import ModalView from '../../components/rover_modal';

export default ModalView.extend({
  slideTemplates: {
    image: Mustache.template('new_design/common/media_gallery/image'),
    video: Mustache.template('new_design/common/media_gallery/video'),
  },
  defaults: {
    classes: '',
    slides: [],
  },
  initialize(options) {
    this.options = _.extend({}, this.defaults, options);
    this.options.classes += ' rover-media-modal';
    // Since this modal is full-screen, we don't want scrollfixes
    this.options.applyScrollFix = false;
    ModalView.prototype.initialize.call(this, this.options);
  },
  render() {
    ModalView.prototype.render.call(this);
    this.$modalContent.addClass('rover-media-gallery royalSlider');

    // Build up gallery markup
    const markup = this.getGalleryMarkup(this.options.slides);

    // Inject markup into modal
    this.setContent(markup);

    // Instantiate slider
    this.royalSlider = this.$modalContent
      .royalSlider({
        autoScaleSlider: false,
        controlNavigation: 'none',
        globalCaption: true,
        keyboardNavEnabled: true,
        arrowsNavAutoHide: false,  // if arrows auto-hide, they're never accessible via keyboard
        numImagesToPreload: 2,
      })
      .data('royalSlider');

    if (this.royalSlider) {
      this.initVideoSupport();
    }
  },
  // Adapted from http://help.dimsemenov.com/kb/royalslider-javascript-api/adding-support-for-third-party-video-player
  initVideoSupport() {
    const slider = this.royalSlider;
    let videoPlayer;

    slider.ev.on('rsOnCreateVideoElement', (e, videoSrc) => {
      slider.videoObj = $('<div class="rsVideoObj"></div>');
      _.defer(() => {
        const currSlide = e.target.currSlide;
        const h = currSlide.iH;
        const w = currSlide.iW;
        const backupSrc = currSlide.content.data('video-backup-src');

        videoPlayer = Rover.video.player({
          container: slider.videoObj,
          width: '100%',
          aspectRatio: `${w}:${h}`,
          autoPlay: true,
        });

        videoPlayer.load(videoSrc, backupSrc);
      });
    });

    slider.ev.on('rsOnDestroyVideoElement', e => {
      videoPlayer.remove();
    });
    slider.ev.on('rsAfterSlideChange', e => {
      this.recordViewEvent();
    });
  },
  recordViewEvent() {
    const eventName = this.options.recordedEvent;
    if (!eventName) {
      return;
    }
    const slide = this.options.slides[this.royalSlider.currSlideId];
    const opk = slide ? slide.opk : '';
    dataLayer.push({
      event: eventName,
      imageId: opk,
      providerId: Rover.context.providerId,
    });
  },
  onModalShown() {
    this.royalSlider.updateSliderSize();
    ModalView.prototype.onModalShown.call(this);
    this.recordViewEvent();
  },
  openTo(index) {
    this.royalSlider.goTo(index);
    this.open();
  },
  getSlideMarkup(slide) {
    return this.slideTemplates[slide.type].render(slide);
  },
  getGalleryMarkup(slides) {
    let markup = '';

    _.each(slides, slide => {
      markup += this.getSlideMarkup(slide);
    });

    return markup;
  },
});
