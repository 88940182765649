import { t } from '@lingui/macro';

import Backbone from '@rover/globals/backbone';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import { formatCurrency } from '@rover/utilities/currency';
import inject from '@rover/utilities/injector';
import i18n from '@rover/utilities/translation';

/**
 * Interactions for the contact confirmation flow
 */

$(() => {
  if (!$('body').hasClass('js-contact-confirmation')) {
    return;
  }

  // Simple model for our contact more sitters form data
  const ContactMoreSittersFormModel = Backbone.Model.extend({
    defaults: {
      selectedSitters: [],
      entryPoint: 'post-request',
    },
  });

  // View for the sitter cards that keeps their appearance synced with their
  // hidden checkbox
  const SitterCardSelectView = Backbone.View.extend({
    initialize() {
      this.sitter_pk = this.$('.js-sitter-select-checkbox').val();
      this.currencyIso = this.$('.js-sitter-select-checkbox').data('currencyIso');
      this.amount = this.$('.js-sitter-select-checkbox').data('amount');
      this.listenTo(this.model, 'change', this.render);

      // Force all profile links to open in new tab
      this.$('.js-profile-link').attr('target', '_blank');

      // Initialize the badge popovers
      Rover.stickyPopover(this.$('.js-badge-popover'), {
        container: 'body',
        placement: 'top',
        tracking: true,
      });

      this.render();

      const locale = inject('Rover.context.language', undefined);
      const formattedPrice = formatCurrency(this.amount, this.currencyIso, locale, {
        includeDecimal: false,
        formatSymbol: (currency) => `<sup class="currency-symbol">${currency}</sup>`,
        formatAmount: (amount) => amount.trim(),
      });
      this.$el.find('.js-price-wrap > .price').html(`
        <strong>
        ${formattedPrice}
        </strong>
      `);
    },
    render() {
      // Check to see if this sitter is selected and update UI
      const isSelected = _.indexOf(this.model.get('selectedSitters'), this.sitter_pk) > -1;
      this.$el.toggleClass('selected', isSelected);
    },
  });

  // Main form view
  const ContactMoreSittersFormView = Backbone.View.extend({
    bindings: {
      '.js-sitter-select-checkbox': 'selectedSitters',
    },

    initialize() {
      // Setup form validation

      this.$el.validate({
        submitHandler: _.bind(this.submitHandler, this),
      });

      this.configureStickit();

      // Create views for our sitter-select cards
      this.$('.js-sitter-select').each(
        // eslint-disable-next-line func-names
        _.bind(function (i, el) {
          // eslint-disable-next-line no-new
          new SitterCardSelectView({
            el,
            model: this.model,
          });
        }, this)
      );

      // Preselect an initial number of sitters
      this.preselectSitters(1);

      this.listenTo(this.model, 'change', this.render);
      this.render();
    },

    render() {
      // Setup two way binding between view and model
      this.stickit();

      // Disable submit button if form is invalid
      this.$('.js-contact-more-sitters-submit').prop('disabled', !this.$el.valid());

      // Update submit button text with count
      this.$('.js-contact-more-sitters-submit').html(this.getSubmitText());

      // Inidicate rendering has finished
      this.$el.removeClass('rover-loading');
    },

    /**
     * Preselect first n of the recommended sitters
     * @param  {int} n Number of sitters to preselect
     */
    preselectSitters(n) {
      const preselectedSitters = [];
      this.$('.js-sitter-select-checkbox')
        .slice(0, n)
        .each(
          _.bind((i, el) => {
            preselectedSitters.push($(el).val());
          }, this)
        );

      this.model.set('selectedSitters', preselectedSitters);
    },

    disableForm() {
      this.$('.js-contact-more-sitters-submit').btn('loading');
      this.$('.js-inputs-wrap').addClass('rover-loading');
      this.$('.js-message-content, .js-sitter-select-checkbox').prop('readonly', true);
      this.$('.js-recommended-sitter-contact-skip').addClass('disabled');
    },

    submitHandler(form) {
      this.disableForm();

      // iPhone fix:
      // Defer the submit slightly
      // so the UI changes have time to
      // appear before screen freezes
      _.defer(() => {
        form.submit();
      });
    },

    /**
     * Gets the appropriate button text for the number of sitters selected
     * @return {str} submit button text
     */
    getSubmitText() {
      const count = this.model.get('selectedSitters').length;
      let submitText = i18n._('No Sitters Selected');

      if (count === 1) {
        submitText = i18n._('Contact This Sitter');
      } else if (count > 1) {
        submitText = i18n._(t`Contact These ${count} Sitters`);
      }

      return submitText;
    },

    /**
     * We need to force stickit to use an array to store our checkbox
     * values instead of sometimes treating them as a string and sometimes an array
     */
    configureStickit() {
      Backbone.Stickit.addHandler({
        selector: '.js-sitter-select-checkbox',
        events: ['change'],
        // eslint-disable-next-line no-unused-vars
        update($el, val = [], model, options) {
          $el.each((i, el) => {
            const checkbox = Backbone.$(el);
            const checked = _.contains(val, checkbox.val());
            checkbox.prop('checked', checked);
          });
        },
        getVal($el) {
          const val = _.reduce(
            $el,
            (memo, el) => {
              const checkbox = Backbone.$(el);
              if (checkbox.prop('checked')) {
                memo.push(checkbox.val());
              }
              return memo;
            },
            []
          );
          return val;
        },
      });
    },
  });

  // eslint-disable-next-line func-names
  const init = function () {
    // Init the form view and model if there's a contact form on the page
    const $contactMoreSittersForm = $('.js-contact-more-sitters-form');
    if ($contactMoreSittersForm.length) {
      const formModel = new ContactMoreSittersFormModel();
      // eslint-disable-next-line no-new
      new ContactMoreSittersFormView({
        el: '.js-contact-more-sitters-form',
        model: formModel,
      });
    }

    // Init validation for 'add home' form
    const $addHomeForm = $('.js-add-home-form');
    if ($addHomeForm.length) {
      $addHomeForm.roverValidate();
    }
  };

  init();
});
