import $ from '@rover/globals/jquery';

function init(this: any) {
  const $gusTips = $('.js-gus-tip');
  $gusTips.each(function () {
    const $this = $(this);
    const shouldShow = $.cookie($this.data('gus-tip-cookie')) !== 'dismissed';

    if (shouldShow) {
      $this.show();
    }
  });
}

function handleGusTipDismiss(this: any) {
  const $gusTip = $(this).closest('.js-gus-tip');
  $.cookie($gusTip.data('gus-tip-cookie'), 'dismissed', {
    expires: 180,
  });
  $gusTip.hide();
}

const exportedGusTips = {
  init,
  handleGusTipDismiss,
};

export default exportedGusTips;
