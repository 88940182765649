import $ from '@rover/globals/jquery';

function init() {
  /**
   * Ajax support for carousel images.
   * Whenever a carousel slides, look for images in the
   * next slide that need to be loaded asynchronously.
   */
  $(document).on('slide.bs.carousel', '[data-ride="carousel"]', (e: any) => {
    // Find the images in the the new slide that need loading
    const srcAttrName = 'data-src';
    const loadingClass = 'rover-loading';
    const $newSlide = $(e.relatedTarget);
    const $imagesToProcess = $newSlide.find(`img[${srcAttrName}]`);
    $imagesToProcess.each(function () {
      // Find the image source that needs to be loaded
      const $img = $(this);
      const newSrc = $img.attr(srcAttrName);
      const $tmpImg = $('<img>');
      // Define behavior for when the image is finished loading
      $tmpImg.one('load', () => {
        $img.removeAttr(srcAttrName);
        $img.attr('src', newSrc || '');
        $img.unwrap();
        $newSlide.trigger('loaded');
      });
      // Update UI to reflect loading and start loading image
      $img.wrap(`<div class="img-responsive ${loadingClass}"></div>`);
      $tmpImg.attr('src', newSrc || '');
    });
  });
}

const exportedInit = { init };

export default exportedInit;
