import type { DataLayerEvent } from '@rover/types';
import { localStorage } from '@rover/utilities/storage';

export const EVENTS_KEY = 'navigationEvents';

const saveNavigationEvent = (event: DataLayerEvent) => {
  if (event && event.event) {
    const data = localStorage.getItem(EVENTS_KEY);
    const navigationEvents = (data && JSON.parse(data)) || [];
    const newNavigationEvents = [...navigationEvents, event];
    const newData = JSON.stringify(newNavigationEvents);
    localStorage.setItem(EVENTS_KEY, newData);
  }
};

export default saveNavigationEvent;
