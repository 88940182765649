// https://help.branch.io/using-branch/docs/creating-a-deep-link#section-redirections
import appendUrlParams from '@rover/rsdk/src/modules/Network/appendUrlParams';
import { getCookie } from '@rover/rsdk/src/modules/Network/cookies';
import { snakeify } from '@rover/rsdk/src/modules/Network/object-utilities';
import { addUtmParamsToUrl } from '@rover/rsdk/src/modules/Network/utm';
import type { UtmParams } from '@rover/types/src/ImagePicker';

import getUrlParameter from './getUrlParameter';

declare global {
  interface Window {
    Rover: Record<string, any>;
  }
}

export const appendFirstHttpReferrer = (
  deepLinkWithParams: string,
  firstHttpReferrer: string | null
): string => {
  if (!firstHttpReferrer) return deepLinkWithParams;
  return appendUrlParams(deepLinkWithParams, {
    '~placement': encodeURIComponent(firstHttpReferrer),
  });
};

export const appendAmbassador = (deepLinkWithParams: string): string => {
  const ambassadorMbsyCode = getUrlParameter('mbsy');
  if (!ambassadorMbsyCode) return deepLinkWithParams;
  const promoCode = getUrlParameter('promo_code');
  const utmSource = getUrlParameter('utm_source');
  const utmContent = getUrlParameter('utm_content');

  const extraAmbassadorParams = {
    mbsy: ambassadorMbsyCode,
    campaignid: getUrlParameter('campaignid') || '',
    ...(promoCode && {
      promoCode,
    }),
    ...(utmSource && {
      utmSource,
    }),
    ...(utmContent && {
      utmContent,
    }),
  };

  return appendUrlParams(deepLinkWithParams, snakeify(extraAmbassadorParams));
};

export const appendRoverCid = (deepLinkWithParams: string): string => {
  const roverCid = getCookie('rover_cid');
  if (!roverCid) return deepLinkWithParams;
  return appendUrlParams(deepLinkWithParams, {
    rover_cid: roverCid,
  });
};

export const appendAloomaDistinctId = (deepLinkWithParams: string): string => {
  const aloomaCookie = getCookie('mp_rover_alooma');

  if (aloomaCookie) {
    const aloomaDistinctId = JSON.parse(decodeURIComponent(`${aloomaCookie}`)).distinct_id;

    if (aloomaDistinctId) {
      return appendUrlParams(deepLinkWithParams, {
        distinct_id: aloomaDistinctId,
      });
    }
  }

  return deepLinkWithParams;
};

const getDeepLinkWithParams = (
  deepLink: string,
  utmParams: UtmParams,
  firstHttpReferrer: string | null
): string => {
  let dl = deepLink;
  dl = addUtmParamsToUrl(dl, utmParams);
  dl = appendFirstHttpReferrer(dl, firstHttpReferrer);
  dl = appendAmbassador(dl);
  dl = appendRoverCid(dl);
  dl = appendAloomaDistinctId(dl);
  return dl;
};

export const generateRouteUrl = (
  branchKey: string,
  deepLinkWithParams: string,
  utmParams: UtmParams,
  firstHttpReferrer: string | null
): string => {
  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
  const afterClickUrl = encodeURIComponent(window.location.href);
  const deeplinkPath = encodeURIComponent(deepLinkWithParams.split('rover://')[1]);

  let routeUrl = appendUrlParams(`https://r.rover.com/a/${branchKey}`, {
    $deeplink_path: deeplinkPath,
    $after_click_url: afterClickUrl,
  });

  routeUrl = addUtmParamsToUrl(routeUrl, utmParams);
  routeUrl = appendFirstHttpReferrer(routeUrl, firstHttpReferrer);
  routeUrl = appendUrlParams(routeUrl, { '~stage': 'mweb_app_banner' });

  return routeUrl;
};

export const getRouteUrlContextless = (
  branchKey: string,
  utmParams: UtmParams,
  firstHttpReferrer: string | null,
  deepLink = 'rover://campaign/'
): string => {
  const deepLinkWithParams = getDeepLinkWithParams(deepLink, utmParams, firstHttpReferrer);
  return generateRouteUrl(branchKey, deepLinkWithParams, utmParams, firstHttpReferrer);
};

export const getRouteURL = (deepLink = 'rover://campaign/'): string => {
  const {
    deeplink: roverDeepLink,
    context: { branchKey },
    UTMParameters: utmParams,
    firstHttpReferrer,
  } = window.Rover; // eslint-disable-line rover/no-platform-specific-globals-or-imports
  return getRouteUrlContextless(branchKey, utmParams, firstHttpReferrer, roverDeepLink || deepLink);
};

export default getRouteUrlContextless;
