function init(): void {
  /**
   * Ensure that window always has the hasOwnProperty method on it, as
   * older versions of IE do not have this, and we are testing for jQuery presence
   * using it.
   */
  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports, func-names
  window.hasOwnProperty = function (obj) {
    return !!this[obj];
  };

  /**
   * Prevent errors from calls to console.* from browsers that don't support it.
   */
  // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
  if (!Object.prototype.hasOwnProperty.call(window, 'console')) {
    // @ts-expect-error
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    window.console = {
      assert() {},
      clear() {},
      count() {},
      debug() {},
      dir() {},
      dirxml() {},
      error() {},
      group() {},
      groupCollapsed() {},
      groupEnd() {},
      info() {},
      log() {},
      profile() {},
      profileEnd() {},
      table() {},
      time() {},
      timeEnd() {},
      timeStamp() {},
      trace() {},
      warn() {},
    };
  }
}

const exportedInit = { init };

export default exportedInit;
