import Rover from '@rover/globals/Rover';
import { dispatchCrossPageEvents } from '@rover/utilities/fireCrossPageEvent';
import { emitCoreWebVitals } from '@rover/utilities/webVitals';

// check if there is a Rover.context.person.opk to set userIsAuthenticated
// there may not be a Rover.context.person object
const userIsAuthenticated = !!Rover.context.person?.opk;

const fireCWVMetricsEvents = async () => {
  await emitCoreWebVitals(Rover.context.pageCategory, userIsAuthenticated);
};

window.addEventListener('load', () => fireCWVMetricsEvents());

const fireCrossPageEvents = async () => {
  dispatchCrossPageEvents();
};

window.addEventListener('load', () => fireCrossPageEvents());
