import { Logger } from '@rover/rsdk/src/modules/Logging';
import { DEFAULT_CURRENCY_ISO, DEFAULT_LANG } from '@rover/shared/js/constants/i18n/language';

type FormatOptions = {
  includeDecimal?: boolean;
  formatSymbol?: ((input: string) => string) | undefined;
  formatAmount?: ((input: string) => string) | undefined;
};

export function getIntlNumberFormat(
  currencyIso = DEFAULT_CURRENCY_ISO,
  locale = DEFAULT_LANG
): Intl.NumberFormat {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyIso,
  });
}

export function formatCurrency(
  amount: string | number,
  currencyIso = DEFAULT_CURRENCY_ISO,
  locale = DEFAULT_LANG,
  { includeDecimal = true, formatSymbol, formatAmount }: FormatOptions = {}
): string {
  const intlNumber = getIntlNumberFormat(currencyIso, locale);
  if (!intlNumber) {
    Logger.info('formatToParts error DEV-87345', {
      error: 'error executing formatToParts',
      currencyIso: `${currencyIso}`,
      locale: `${locale}`,
    });
  }
  const parts = intlNumber.formatToParts(Number(amount));
  let formattedCurrency = '';
  let formattedAmount = '';
  let currencyIndex = 0;
  let isNegative = false;
  parts.forEach((part, index) => {
    const { type, value } = part;
    if (type === 'minusSign') isNegative = true;

    if (type === 'currency') {
      currencyIndex = index;
      formattedCurrency = value;
    } else if (includeDecimal || !['decimal', 'fraction'].includes(type)) {
      formattedAmount += value;
    }
  });

  if (typeof formatSymbol === 'function') {
    formattedCurrency = formatSymbol(formattedCurrency);
  }

  if (typeof formatAmount === 'function') {
    formattedAmount = formatAmount(formattedAmount);
  }

  if (currencyIndex === 1 && isNegative) return `-${formattedCurrency}${formattedAmount.slice(1)}`;

  if (currencyIndex > 0) {
    return formattedAmount + formattedCurrency;
  }

  return formattedCurrency + formattedAmount;
}

type Price = {
  amount: number;
  // eslint-disable-next-line camelcase
  currency_iso?: string;
};

export function formatCurrencyFromPriceObj(priceObj: Price, locale: string): string {
  return formatCurrency(String(priceObj.amount), priceObj.currency_iso, locale);
}

type Config = {
  symbol: string;
  symbolOnLeft: boolean;
  thousandsSeparator: string;
  decimalSeparator: string;
  decimalDigits: number;
};

export type CurrencyLocalData = {
  currencyConfig: {
    symbol: string;
    decimalDigits: number;
  };
  localeConfig: {
    locale: string;
    symbolOnLeft: boolean;
    thousandsSeparator: string;
    decimalSeparator: string;
  };
};

const getConfigFromFormatter = (currencyIso: string, locale = DEFAULT_LANG): Config => {
  const formatter = getIntlNumberFormat(currencyIso, locale);
  const config: Partial<Config> = {};
  formatter.formatToParts(100000.0).forEach(({ type, value }, index) => {
    if (type === 'currency') {
      config.symbol = value;
      config.symbolOnLeft = index === 0;
    }

    if (type === 'group') {
      config.thousandsSeparator = value;
    }

    if (type === 'decimal') {
      config.decimalSeparator = value;
    }

    if (type === 'fraction') {
      config.decimalDigits = value.length;
    }
  });
  return config as Config;
};

export function getCurrencyAndLocaleConfig(
  currencyIso: string,
  locale = DEFAULT_LANG
): CurrencyLocalData {
  const config = getConfigFromFormatter(currencyIso, locale);
  return {
    currencyConfig: {
      symbol: config.symbol,
      decimalDigits: config.decimalDigits,
    },
    localeConfig: {
      locale,
      symbolOnLeft: config.symbolOnLeft,
      thousandsSeparator: config.thousandsSeparator,
      decimalSeparator: config.decimalSeparator,
    },
  };
}
