import { t } from '@lingui/macro';

import dataLayer from '@rover/globals/dataLayer';
import $ from '@rover/globals/jquery';
import Rover from '@rover/globals/Rover';
import { isAndroid, isMobileEmbedded } from '@rover/rsdk/src/modules/Network/userAgent';
import { getRouteURL } from '@rover/utilities/deeplink';
import fireDataLayerEvent from '@rover/utilities/fireDataLayerEvent';
import i18n from '@rover/utilities/translation';

// Should have the same value as $screen-sm in _variables.scss
const SCREEN_SM_SIZE_PX = 768;

export default class {
  static closeBanner() {
    $('.js-app-banner').addClass('hidden');
    $('body').removeClass('has-banner');
    $.cookie('appBanner', true, { expires: 90 });
  }

  static fireClickEvent() {
    dataLayer.push({
      event: 'mobile-web-app-download-install',
    });
  }

  static isPossibleBanner() {
    return !$.cookie('appBanner');
  }

  static init() {
    if (!this.isPossibleBanner() || isMobileEmbedded()) {
      return;
    }

    const appInstallBannerShownEvent = {
      event: 'navigation-app-install-banner-shown',
      nonInteractive: true,
    };

    const reviewsText = i18n._(t`Reviews`);
    Rover.context.iOSReviewCount = `76.8k+ ${reviewsText}`;
    Rover.context.androidReviewCount = `4,300+ ${reviewsText}`;

    let isEventSent = false;

    if (window.innerWidth <= SCREEN_SM_SIZE_PX && document.querySelector('.app-banner')) {
      fireDataLayerEvent({ ...appInstallBannerShownEvent });
      isEventSent = true;
      $('body').addClass('has-banner');
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= SCREEN_SM_SIZE_PX && document.querySelector('.app-banner')) {
        if (!isEventSent) {
          fireDataLayerEvent({ ...appInstallBannerShownEvent });
          isEventSent = true;
        }
        $('body').addClass('has-banner');
      }
      if (window.innerWidth > SCREEN_SM_SIZE_PX) {
        $('body').removeClass('has-banner');
      }
    });

    const routeURL = getRouteURL();

    $('.js-app-banner').removeClass('hidden');
    $('.js-app-install-link').attr('href', routeURL);
    $('.js-app-install-link').on('click', this.fireClickEvent);

    $('.js-close-app-banner').on('click', this.closeBanner);
    if (isAndroid()) {
      $('.js-review-count').text(Rover.context.androidReviewCount);
    } else {
      $('.js-review-count').text(Rover.context.iOSReviewCount);
    }
  }
}
