import { CookiePurpose, createCookie } from '@rover/rsdk/src/modules/Network/cookies';

const leverCookies = {
  'lever-origin': 'applied',
  'lever-source': 'Site_Footer',
};

const createCookieFromObj = ([key, value]) =>
  createCookie(CookiePurpose.Performance, key, value, 30);

const setLeverCookies = () => Object.entries(leverCookies).map(createCookieFromObj);

export default setLeverCookies;
